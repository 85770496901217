import { Card, Space } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ReservationDetailPage = () => {
  const location = useLocation();
  const reservationData = location.state?.reservationData;

  return (
    <ReservationDetailWrapper>
      <Space direction="vertical" size={16}>
        <Card title="예약 및 문의 상세 내역" style={{ width: 800 }}>
          <ReservationDataWrapper>
            <span>이름 : {reservationData.name}</span>
            <span>전화번호 : {reservationData.digit}</span>
            <span>지점명 : {reservationData.office_selection}</span>
            <span>사진 선택 : {reservationData.shooting_selection}</span>
            <span>희망 날짜 : {moment(reservationData.desired_date).format('YYYY-MM-DD')}</span>
            <span>작성 날짜 : {moment(reservationData.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
            <span>문의 내용 : {reservationData.content}</span>
          </ReservationDataWrapper>
        </Card>
      </Space>
    </ReservationDetailWrapper>
  );
};

export default ReservationDetailPage;

const ReservationDetailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReservationDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;