import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { useAtom } from 'jotai';
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Input, Pagination, Space, message } from 'antd';

import { CloudUploadOutlined } from '@ant-design/icons';
import { tokenAtom } from '../../../stores';
import client from '../../../api/axios';
import { checkValidationImage } from '../../../utils';
import MEDIA_QUERY from '../../../constants/mediaQuery';
// import defaultImg from 'images/noImage.png';

type postBranchesDataType = {
  id?: number;
  office_name?: string;
  address?: string;
  digit?: string;
  img_url?: string;
  ins_link_url?: string;
  you_link_url?: string;
  fac_link_url?: string;
  sms_link_url?: string;
};

const AdminBranchesPage = () => {
  const [userToken] = useAtom(tokenAtom);

  const [form] = Form.useForm();

  const [hoverBranch, setHoverBranch] = useState(null);
  const [activeBranch, setActiveBranch] = useState<postBranchesDataType | null>(null);

  const [branchesData, setBranchesData] = useState<{ results: { results: any[] } } | null>(null);

  const [uploadBranchesData, setUploadBranchesData] = useState<postBranchesDataType>({});
  const [updateBranchesData, setUpdateBranchesData] = useState<postBranchesDataType>({});

  const [totalBranches, setTotalBranches] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const imgRef = useRef<HTMLInputElement>(null);

  const [fileUrl, setFileUrl] = useState('');
  const [image, setImage] = useState<any>();

  const [isClickCreateBtn, setIsClickCreateBtn] = useState(false);

  const handleCreateBranch = () => {
    setIsClickCreateBtn(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // const handleImageError = (event: any) => {
  //   event.target.src = defaultImg;
  // };

  // Branches 데이터 조회
  const handleQueryBranches = async () => {
    try {
      const response = await client.get(`api/v1/branches/branches?page=${currentPage}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setBranchesData(response.data);
      setTotalBranches(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };

  const onClickUpload = () => {
    imgRef.current?.click();
  };

  // 이미지 미리보기
  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = checkValidationImage(e.target.files?.[0]);
    if (!isValid) return;

    try {
      if (imgRef.current?.files) {
        const file = imgRef.current.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            setFileUrl(reader.result);
          }
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Branches 데이터 업로드
  const handleUploadBranch = async () => {
    try {
      const values = form.getFieldsValue();
      const formData = new FormData();
      formData.append('image', image);
      formData.append('tag', 'branches'); // tag는 페이지에 따라 다르게 입력

      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setUploadBranchesData({
        ...values,
        img_url: response.data.img_url,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Branches 데이터 수정
  const handleUpdateBranch = async () => {
    try {
      const values = form.getFieldsValue();
      const formData = new FormData();
      formData.append('image', image);
      formData.append('tag', 'branches'); // tag는 페이지에 따라 다르게 입력

      // 이미지 검증 및 스토리지 저장
      const response = await client.post('api/v1/images/image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setUpdateBranchesData({
        ...values,
        img_url: response.data.img_url,
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Branches 데이터 삭제
  const handleDeleteBranch = async (branchId: number) => {
    try {
      await client.delete(`api/v1/branches/branches/${branchId}/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      handleQueryBranches();
    } catch (error) {
      console.error(error);
    }
  };

  // 페이지가 변하면 리렌더링
  useEffect(() => {
    handleQueryBranches();
  }, [totalBranches, currentPage]);

  // 이미지 파일이 변하면 리렌더링
  useEffect(() => {
    form.setFieldsValue({ img_url: fileUrl });
  }, [fileUrl]);

  // 브랜치 추가
  useEffect(() => {
    const postBranchData = async () => {
      // uploadBranchesData가 빈 객체가 아닐 때만 post 요청
      if (Object.keys(uploadBranchesData).length > 0) {
        await client.post('api/v1/branches/branches/', uploadBranchesData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${userToken}`,
          },
          withCredentials: true,
        });

        handleQueryBranches();

        message.success({ content: '지점 정보가 추가되었습니다.' });

        setIsClickCreateBtn(false);
        setFileUrl('');
        form.resetFields();
      }
    };

    postBranchData();
  }, [uploadBranchesData]);

  // 브랜치 수정
  useEffect(() => {
    const editBranchData = async () => {
      if (Object.keys(updateBranchesData).length > 0) {
        const cleanedData = Object.entries(updateBranchesData).reduce((acc: any, [key, value]) => {
          if (value !== '' && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {});

        await client.post(`api/v1/branches/branches/${activeBranch?.id}/`, cleanedData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${userToken}`,
          },
          withCredentials: true,
        });

        message.success({ content: '지점 정보가 수정되었습니다.' });

        setIsClickCreateBtn(false);
        setFileUrl('');
        form.resetFields();
      }
    };

    editBranchData();
  }, [updateBranchesData]);

  return (
    <>
      <Helmet>
        <title>관리자 | Branches — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          {!isClickCreateBtn && !activeBranch && (
            <BranchWrapper>
              <BranchTitle>BRANCHES</BranchTitle>
              <BranchGridWrapper>
                {branchesData?.results.results.map((item: any, index: number) => (
                  <>
                    <BranchImagesWrapper
                      key={index}
                      onMouseEnter={() => setHoverBranch(item)}
                      onMouseLeave={() => setHoverBranch(null)}
                    >
                      <BranchImage src={item.img_url} alt={`${item.office_name} 이미지`} />
                      <BranchInfoWrapper>
                        <BranchName>{item.office_name}</BranchName>
                        <BranchAddress>{item.address}</BranchAddress>
                        <BranchSnsWrapper>
                          <a href={item?.ins_link_url}>
                            <SnsImage src="/images/insta.svg" alt={`${item.office_name} 인스타그램 바로가기 버튼`} />
                          </a>
                          <a href={item?.item?.you_link_url}>
                            <SnsImage src="/images/youtube.svg" alt={`${item.office_name} 인스타그램 바로가기 버튼`} />
                          </a>
                          <a href={item?.fac_link_url}>
                            <SnsImage src="/images/facebook.svg" alt={`${item.office_name} 인스타그램 바로가기 버튼`} />
                          </a>
                          <a href={item?.sms_link_url}>
                            <SnsImage src="/images/mail.svg" alt={`${item.office_name} 인스타그램 바로가기 버튼`} />
                          </a>
                        </BranchSnsWrapper>
                      </BranchInfoWrapper>
                      {hoverBranch === item && (
                        <ButtonContainer>
                          <EditButton onClick={() => setActiveBranch(item)}>수정</EditButton>
                          <DeleteButton onClick={() => handleDeleteBranch(item.id)}>삭제</DeleteButton>
                        </ButtonContainer>
                      )}
                    </BranchImagesWrapper>
                  </>
                ))}
              </BranchGridWrapper>

              {/* 페이지네이션 */}
              <Pagination defaultPageSize={4} current={currentPage} total={totalBranches} onChange={handlePageChange} />
            </BranchWrapper>
          )}

          {/* 지점 추가 모드 */}
          {isClickCreateBtn && (
            <BranchesEditWrapper>
              <Space direction="vertical" size={16}>
                <Card title="Branches 정보 추가" style={{ width: 800 }}>
                  <BranchesInfoForm name="branchesForm" onFinish={handleUploadBranch} autoComplete="off" form={form}>
                    <Form.Item<postBranchesDataType>
                      label="이미지"
                      name="img_url"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: true, message: '지점 이미지를 선택해주세요.' }]}
                    >
                      {fileUrl ? (
                        <UploadImage onClick={onClickUpload} src={fileUrl} alt="Branches 페이지 지점 이미지" />
                      ) : (
                        <UploadButton onClick={onClickUpload}>
                          <UploadIcon />
                        </UploadButton>
                      )}
                      <input
                        type="file"
                        ref={imgRef}
                        accept="image/*"
                        onChange={onChangeFile}
                        style={{ display: 'none' }}
                      />
                    </Form.Item>

                    <div>
                      <BranchesInfoWrapper>
                        <Form.Item<postBranchesDataType>
                          label="지점명"
                          name="office_name"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ required: true, message: '지점명을 입력해주세요.' }]}
                        >
                          <Input placeholder="경기 이천점" />
                        </Form.Item>

                        <Form.Item<postBranchesDataType>
                          label="주소"
                          name="address"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 32 }}
                          rules={[{ required: true, message: '주소를 입력해주세요.' }]}
                        >
                          <Input placeholder="경기도 성남시 분당구" />
                        </Form.Item>

                        <Form.Item<postBranchesDataType>
                          label="전화번호"
                          name="digit"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 32 }}
                          rules={[{ required: true, message: '전화번호를 입력해주세요.' }]}
                        >
                          <Input placeholder="1833-9607" />
                        </Form.Item>

                        <Form.Item<postBranchesDataType>
                          label="instagram"
                          name="ins_link_url"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ message: '인스타그램 링크를 입력해주세요.' }]}
                        >
                          <Input placeholder="https://www.instagram.com/사용자이름" />
                        </Form.Item>

                        <Form.Item<postBranchesDataType>
                          label="youtube"
                          name="you_link_url"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ message: '유튜브 링크를 입력해주세요.' }]}
                        >
                          <Input placeholder="https://www.youtube.com/@채널명" />
                        </Form.Item>

                        <Form.Item<postBranchesDataType>
                          label="facebook"
                          name="fac_link_url"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ message: '페이스북 링크를 입력해주세요.' }]}
                        >
                          <Input placeholder="https://www.facebook.com/아이디" />
                        </Form.Item>

                        <Form.Item<postBranchesDataType>
                          label="email"
                          name="sms_link_url"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ message: '이메일 링크를 입력해주세요.' }]}
                        >
                          <Input placeholder="matilto:이메일 주소" />
                        </Form.Item>
                      </BranchesInfoWrapper>
                      <EditButtonWrapper>
                        <CancelButton
                          onClick={() => {
                            setIsClickCreateBtn(false);
                            form.resetFields();
                          }}
                        >
                          취소
                        </CancelButton>
                        <SaveButton
                          htmlType="submit"
                          style={{
                            boxShadow: 'none',
                          }}
                        >
                          저장
                        </SaveButton>
                      </EditButtonWrapper>
                    </div>
                  </BranchesInfoForm>
                </Card>
              </Space>
            </BranchesEditWrapper>
          )}

          {/* 지점 수정 모드  */}
          {activeBranch && (
            <>
              <BranchesEditWrapper>
                <Space direction="vertical" size={16}>
                  <Card title="Branches 정보 수정" style={{ width: 800 }}>
                    <BranchesInfoForm name="branchesForm" onFinish={handleUpdateBranch} autoComplete="off" form={form}>
                      <Form.Item<postBranchesDataType>
                        label="이미지"
                        name="img_url"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: '지점 이미지를 선택해주세요.' }]}
                      >
                        <UploadImage
                          onClick={onClickUpload}
                          src={fileUrl ? fileUrl : activeBranch?.img_url ? activeBranch?.img_url : image}
                          alt="Branches 페이지 지점 이미지"
                        />
                        {!fileUrl && !activeBranch?.img_url && (
                          <UploadButton onClick={onClickUpload}>
                            <UploadIcon />
                          </UploadButton>
                        )}
                        <input
                          type="file"
                          ref={imgRef}
                          accept="image/*"
                          onChange={onChangeFile}
                          style={{ display: 'none' }}
                        />
                      </Form.Item>

                      <div>
                        <BranchesInfoWrapper>
                          <Form.Item<postBranchesDataType>
                            label="지점명"
                            name="office_name"
                            initialValue={activeBranch?.office_name}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: true, message: '지점명을 입력해주세요.' }]}
                          >
                            <Input placeholder="경기 이천점" />
                          </Form.Item>

                          <Form.Item<postBranchesDataType>
                            label="주소"
                            name="address"
                            initialValue={activeBranch?.address}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 32 }}
                            rules={[{ required: true, message: '주소를 입력해주세요.' }]}
                          >
                            <Input placeholder="경기도 성남시 분당구" />
                          </Form.Item>

                          <Form.Item<postBranchesDataType>
                            label="전화번호"
                            name="digit"
                            initialValue={activeBranch?.digit}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 32 }}
                            rules={[{ required: true, message: '전화번호를 입력해주세요.' }]}
                          >
                            <Input placeholder="1833-9607" />
                          </Form.Item>

                          <Form.Item<postBranchesDataType>
                            label="instagram"
                            name="ins_link_url"
                            initialValue={activeBranch?.ins_link_url}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ message: '인스타그램 링크를 입력해주세요.' }]}
                          >
                            <Input placeholder="https://www.instagram.com/사용자이름" />
                          </Form.Item>

                          <Form.Item<postBranchesDataType>
                            label="youtube"
                            name="you_link_url"
                            initialValue={activeBranch?.you_link_url}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ message: '유튜브 링크를 입력해주세요.' }]}
                          >
                            <Input placeholder="https://www.youtube.com/@채널명" />
                          </Form.Item>

                          <Form.Item<postBranchesDataType>
                            label="facebook"
                            name="fac_link_url"
                            initialValue={activeBranch?.fac_link_url}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ message: '페이스북 링크를 입력해주세요.' }]}
                          >
                            <Input placeholder="https://www.facebook.com/아이디" />
                          </Form.Item>

                          <Form.Item<postBranchesDataType>
                            label="email"
                            name="sms_link_url"
                            initialValue={activeBranch?.sms_link_url}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ message: '이메일 링크를 입력해주세요.' }]}
                          >
                            <Input placeholder="matilto:이메일 주소" />
                          </Form.Item>
                        </BranchesInfoWrapper>
                        <EditButtonWrapper>
                          <CancelButton
                            onClick={() => {
                              setIsClickCreateBtn(false);
                              setActiveBranch(null);
                              setFileUrl('');
                              form.resetFields();
                            }}
                          >
                            취소
                          </CancelButton>
                          <SaveButton
                            htmlType="submit"
                            style={{
                              boxShadow: 'none',
                            }}
                          >
                            저장
                          </SaveButton>
                        </EditButtonWrapper>
                      </div>
                    </BranchesInfoForm>
                  </Card>
                </Space>
              </BranchesEditWrapper>
            </>
          )}

          {!isClickCreateBtn && !activeBranch && (
            <CreateBranchButton onClick={handleCreateBranch}>지점 추가</CreateBranchButton>
          )}
        </OuterWrapper>
      </Container>
    </>
  );
};

export default AdminBranchesPage;

const Container = styled.div`
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: -3rem;

  ${MEDIA_QUERY.sm} {
    margin-bottom: -6rem;
  }
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BranchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 67.5rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    margin-bottom: 0;
  }

  ${MEDIA_QUERY.xs} {
    padding: 0 1rem;
  }
`;

const BranchTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  letter-spacing: 6.8px;
  line-height: 21px;
  color: #1e1f18;

  ${MEDIA_QUERY.lg} {
    margin-bottom: 7.4rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 2.6px;
    margin: 7.8rem 0 5.8rem;
  }
`;

interface BranchGridWrapperProps {
  isHoverBranches: boolean;
  isActiveBranches: boolean;
}

const BranchGridWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${MEDIA_QUERY.sm} {
    grid-template-columns: 1fr;
  }
`;

const BranchImagesWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 1.5rem;

  ${MEDIA_QUERY.xs} {
    padding: 1rem;
  }
`;

const BranchInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MEDIA_QUERY.lg} {
    width: 16rem;
    height: 16.5rem;
    padding: 2.4rem 2.8rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 14rem;
    height: 14.5rem;
    padding: 0 0 1.8rem 2.4rem;
  }
`;

const BranchImage = styled.img`
  width: 16rem;
  height: 16.5rem;

  ${MEDIA_QUERY.xs} {
    width: 12rem;
    height: 12.5rem;
  }
`;

const BranchName = styled.span`
  font-family: 'NanumSquareNeoBold';

  font-weight: 700;
  color: #424530;

  ${MEDIA_QUERY.lg} {
    font-size: 1.2rem;
    margin-bottom: 2.5rem;
  }
`;

const BranchAddress = styled.p`
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1e1f18;

  ${MEDIA_QUERY.sm} {
    margin: 0 0 1.7rem;
  }
`;

const BranchSnsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SnsImage = styled.img`
  margin-right: 1.2rem;

  ${MEDIA_QUERY.lg} {
    width: 1.2rem;
    height: 1.2rem;
  }

  ${MEDIA_QUERY.sm} {
    margin-right: 1.1rem;
  }
`;

// 관리자 스타일
const BranchesEditButton = styled.button`
  width: 10rem;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  cursor: pointer;
`;

const BranchesEditWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BranchesInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BranchesInfoForm = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditButtonWrapper = styled.div`
  top: 30%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled.button`
  width: 5rem;
  height: 3rem;
  border: 1px solid #000;
  margin-right: 1rem;
  color: #000;
  background-color: #fff;
  cursor: pointer;
`;

const SaveButton = styled(Button)`
  width: 5rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  color: #fff;
  background-color: #000;
  cursor: pointer;
`;

const UploadImage = styled.img`
  width: 29.8rem;
  max-height: 44.7rem;
  cursor: pointer;

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 48rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
    height: 100%;
  }
`;

const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30rem;
  height: 30rem;

  outline: none;
  border: none;
  background-color: #ebebeb;
  cursor: pointer;

  ${MEDIA_QUERY.sm} {
    width: 32rem;
    height: 48rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 100%;
    height: 100%;
  }
`;

const UploadIcon = styled(CloudUploadOutlined)`
  color: #1e1f18;
  font-size: 3rem;
`;

const CreateBranchButton = styled.button`
  width: 8rem;
  height: 3rem;
  border: none;
  margin: 2rem 0 0 49rem;
  color: #fff;
  font-family: 'ArgentumSansRegular';
  background-color: #000;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10rem;
`;

const EditButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  border: none;
  color: #fff;
  background-color: #000;
  opacity: 1;
  margin-right: 1rem;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  width: 5rem;
  height: 2.5rem;
  border: none;
  color: #fff;
  background-color: #ce2c30;
  opacity: 1;
  cursor: pointer;
`;
