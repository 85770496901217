import styled from 'styled-components';
import MEDIA_QUERY from '../../../constants/mediaQuery';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import client from '../../../api/axios';
import CompleteForm from '../CompleteForm';
import useMediaQuery from '../../../hook/useMediaQuery';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import useOnHoverOutside from '../../../hook/useOnHoverOutside';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../stores';

const Form = () => {
  const { isSm, isLg } = useMediaQuery();

  const [userToken] = useAtom(tokenAtom);

  const schema = yup.object({
    name: yup.string().required('이름을 입력해주세요'),
    digit: yup.string().required('연락처를 입력해주세요'),
    password: yup
      .string()
      .matches(/^.{4,8}$/, '비밀번호는 4~8자 이내로 입력해주세요')
      .required('비밀번호를 입력해주세요.'),
    rePassword: yup
      .string()
      .matches(/^.{4,8}$/, '비밀번호는 4~8자 이내로 입력해주세요')
      .oneOf([yup.ref('password')], '비밀번호가 일치하지 않습니다.')
      .required('비밀번호를 한번 더 입력해주세요.'),
    shooting_selection: yup.string().required('촬영 종류를 선택해주세요'),
    office_selection: yup.string().required('촬영할 지점을 선택해주세요'),
    desired_date: yup.mixed().required('촬영할 날짜를 선택해주세요'),
    content: yup.string(),
    privacyPolicy: yup.boolean(),
  });

  const { register, handleSubmit, formState, setValue, control } = useForm({
    resolver: yupResolver(schema),
  });

  const [isSubmitForm, setIsSubmitForm] = useState(false);

  // 촬영 선택, 지점 선택
  const [isShowPhotoOptions, setIsShowPhotoOptions] = useState(false);
  const [isShowfranchiseOptions, setIsShowfranchiseOptions] = useState(false);

  const [currentPhotoOption, setCurrentPhotoOption] = useState('만삭 촬영');
  const [currentfranchiseOption, setCurrentfranchiseOption] = useState('경기 이천');

  const photoSelectboxRef = useRef(null);
  const franchiseSelectboxRef = useRef(null);

  // 희망 날짜
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const today = new Date();

  // Reservation Post
  const handleSubmitForm = async (data: any) => {
    if (!data.privacyPolicy) {
      alert('개인 정보 취급 방침에 동의해주세요');
      return;
    }

    // 희망 날짜 포맷 변경
    let newData = { ...data };
    newData.selectDate = moment(newData.selectDate).format('YYYY-MM-DD');

    try {
      const { rePassword, desired_date, privacyPolicy, ...reservationData } = newData;

      reservationData.desired_date = reservationData.selectDate;
      delete reservationData.selectDate;

      await client.post(`api/v1/contacts/reservation/`, reservationData, {
        headers: {
          'Content-Type': 'application/json',
          //Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });
      setIsSubmitForm(true);
    } catch (error) {
      console.error(error);
    }
  };

  useOnHoverOutside(photoSelectboxRef, () => {
    setIsShowPhotoOptions(false);
  });

  useOnHoverOutside(franchiseSelectboxRef, () => {
    setIsShowfranchiseOptions(false);
  });

  return (
    <>
      {!isSubmitForm && (
        <>
          <ContactTitle>RESERVATION</ContactTitle>
          <FormContainer onSubmit={handleSubmit(handleSubmitForm)}>
            <BasicInfoContainer>
              <BasicInfoWrapper>
                <StrongInfoLabel>이름</StrongInfoLabel>
                <BasicInfoInput
                  placeholder="김지은"
                  autoComplete="off"
                  {...register('name')}
                  style={
                    formState.errors.name?.message ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' } : {}
                  }
                />
                <InputErrorMsg>{formState.errors.name?.message}</InputErrorMsg>
              </BasicInfoWrapper>
              <BasicInfoWrapper>
                <StrongInfoLabel>연락처</StrongInfoLabel>
                <BasicInfoInput
                  placeholder="010-0000-0000"
                  autoComplete="off"
                  {...register('digit')}
                  style={
                    formState.errors.digit?.message ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' } : {}
                  }
                />
                <InputErrorMsg>{formState.errors.digit?.message}</InputErrorMsg>
              </BasicInfoWrapper>
              <BasicInfoWrapper>
                <StrongInfoLabel>비밀번호</StrongInfoLabel>
                <BasicInfoInput
                  type="password"
                  placeholder="4~8자 이내로 입력해주세요"
                  autoComplete="off"
                  {...register('password')}
                  style={
                    formState.errors.password?.message
                      ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' }
                      : {}
                  }
                />
                <InputErrorMsg>{formState.errors.password?.message}</InputErrorMsg>
              </BasicInfoWrapper>
              <BasicInfoWrapper>
                <StrongInfoLabel>비밀번호 확인</StrongInfoLabel>
                <BasicInfoInput
                  type="password"
                  placeholder="******"
                  autoComplete="off"
                  {...register('rePassword')}
                  style={
                    formState.errors.rePassword?.message
                      ? { backgroundColor: '#FFEAE8', border: '1px solid #CE2C30' }
                      : {}
                  }
                />
                <InputErrorMsg>{formState.errors.rePassword?.message}</InputErrorMsg>
              </BasicInfoWrapper>
              <SelectInfoWrapper>
                <StrongInfoLabel>촬영 선택</StrongInfoLabel>
                {isLg && !isSm && (
                  <SelectBoxWrapper ref={photoSelectboxRef} onClick={() => setIsShowPhotoOptions((prev) => !prev)}>
                    <SelectValue>{currentPhotoOption}</SelectValue>
                    <SelectBox show={isShowPhotoOptions}>
                      {/* li 태그를 formState에 등록하기 위한 hidden input */}
                      <input type="hidden" {...register('shooting_selection')} value={currentPhotoOption} />

                      <SelectOption
                        value="만삭 촬영"
                        onClick={() => {
                          setCurrentPhotoOption('만삭 촬영');
                          setValue('shooting_selection', '만삭 촬영');
                        }}
                      >
                        만삭 촬영
                      </SelectOption>
                      <SelectOption
                        value="50일 촬영"
                        onClick={() => {
                          setCurrentPhotoOption('50일 촬영');
                          setValue('shooting_selection', '50일 촬영');
                        }}
                      >
                        50일 촬영
                      </SelectOption>
                      <SelectOption
                        value="100일 촬영"
                        onClick={() => {
                          setCurrentPhotoOption('100일 촬영');
                          setValue('shooting_selection', '100일 촬영');
                        }}
                      >
                        100일 촬영
                      </SelectOption>
                      <SelectOption
                        value="돌 촬영"
                        onClick={() => {
                          setCurrentPhotoOption('돌 촬영');
                          setValue('shooting_selection', '돌 촬영');
                        }}
                      >
                        돌 촬영
                      </SelectOption>
                    </SelectBox>
                  </SelectBoxWrapper>
                )}
                {isSm && (
                  <MobileSelectBox {...register('shooting_selection')}>
                    <MobileSelectOption value="만삭 촬영">만삭 촬영</MobileSelectOption>
                    <MobileSelectOption value="50일 촬영">50일 촬영</MobileSelectOption>
                    <MobileSelectOption value="100일 촬영">100일 촬영</MobileSelectOption>
                    <MobileSelectOption value="돌 촬영">돌 촬영</MobileSelectOption>
                  </MobileSelectBox>
                )}
              </SelectInfoWrapper>
              <SelectInfoWrapper>
                <StrongInfoLabel>지점 선택</StrongInfoLabel>
                {isLg && !isSm && (
                  <SelectBoxWrapper
                    ref={franchiseSelectboxRef}
                    onClick={() => setIsShowfranchiseOptions((prev) => !prev)}
                  >
                    {/* li 태그를 formState에 등록하기 위한 hidden input */}
                    <input type="hidden" {...register('office_selection')} value={currentfranchiseOption} />

                    <SelectValue>{currentfranchiseOption}</SelectValue>

                    <SelectBox show={isShowfranchiseOptions}>
                      {/* <SelectOption
                        value="경기 성남"
                        onClick={() => {
                          setCurrentfranchiseOption('경기 성남');
                          setValue('office_selection', '경기 성남');
                        }}
                      >
                        경기 성남
                      </SelectOption> */}
                      <SelectOption
                        value="경기 이천"
                        onClick={() => {
                          setCurrentfranchiseOption('경기 이천');
                          setValue('office_selection', '경기 이천');
                        }}
                      >
                        경기 이천
                      </SelectOption>
                      <SelectOption
                        value="서울 강남"
                        onClick={() => {
                          setCurrentfranchiseOption('서울 강남');
                          setValue('office_selection', '서울 강남');
                        }}
                      >
                        서울 강남
                      </SelectOption>
                      <SelectOption
                        value="충북 청주"
                        onClick={() => {
                          setCurrentfranchiseOption('충북 청주');
                          setValue('office_selection', '충북 청주');
                        }}
                      >
                        충북 청주
                      </SelectOption>
                    </SelectBox>
                  </SelectBoxWrapper>
                )}
                {isSm && (
                  <MobileSelectBox {...register('office_selection')}>
                    {/* <MobileSelectOption value="경기 성남">경기 성남</MobileSelectOption> */}
                    <MobileSelectOption value="경기 이천">경기 이천</MobileSelectOption>
                    <MobileSelectOption value="서울 강남">서울 강남</MobileSelectOption>
                    <MobileSelectOption value="충북 청주">충북 청주</MobileSelectOption>
                  </MobileSelectBox>
                )}
              </SelectInfoWrapper>
              <SelectDateInputWrapper>
                <StrongInfoLabel>희망 날짜</StrongInfoLabel>
                <Controller
                  name="desired_date"
                  control={control}
                  render={({ field }) => (
                    <StyledDatePicker
                      placeholderText="연도.월.일"
                      dateFormat="yyyy.MM.dd"
                      shouldCloseOnSelect
                      autoComplete="off"
                      minDate={today}
                      selected={selectedDate}
                      onChange={(date: any) => {
                        setSelectedDate(date);
                        field.onChange(date);
                      }}
                      onFocus={(event) => event.target.blur()}
                    />
                  )}
                />
                {!selectedDate && <InputErrorMsg>{formState.errors.desired_date?.message}</InputErrorMsg>}
              </SelectDateInputWrapper>
            </BasicInfoContainer>
            <InqueryWrapper>
              <InqueryLabel>문의 내용</InqueryLabel>
              <InqueryTextarea autoComplete="off" {...register('content')} />
            </InqueryWrapper>
            <div>
              <InqueryWrapper>
                <PersonalLabel>개인정보취급방침</PersonalLabel>
                <InqueryTextarea
                  value={`눈부신 일상은 (이하 ‘회사’는) 고객님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호”에 관한 법률을 준수하고 있습니다. \n \n 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. \n \n 눈부신 일상은 (이하 ‘회사’는) 고객님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호”에 관한 법률을 준수하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. \n \n 눈부신 일상은 (이하 ‘회사’는) 고객님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호”에 관한 법률을 준수하고 있습니다. \n \n  회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.`}
                  readOnly
                />
              </InqueryWrapper>
            </div>
            <PersonalWrapper>
              <PersonalCheckbox id="privacyPolicy" type="checkbox" {...register('privacyPolicy')} />
              <PersonalCheckboxLabel htmlFor="privacyPolicy">
                <PersonalCheckboxText>개인정보취급방침에 동의합니다.</PersonalCheckboxText>
              </PersonalCheckboxLabel>
            </PersonalWrapper>
            <FormButtonWrapper>
              <SubmitButton type="submit">Submit</SubmitButton>
            </FormButtonWrapper>
          </FormContainer>
        </>
      )}

      {/* 예약 문의 폼 전송 완료 페이지 */}
      {isSubmitForm && <CompleteForm />}
    </>
  );
};

export default Form;

const ContactTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 6.8px;
  color: #1e1f18;

  ${MEDIA_QUERY.lg} {
    margin-top: -3rem;
  }

  ${MEDIA_QUERY.sm} {
    color: #474747;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 2.6px;
    margin-top: 7.8rem;
  }
`;

const FormContainer = styled.form`
  ${MEDIA_QUERY.xs} {
    padding: 0 1.5rem;
  }
`;

const BasicInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;

  ${MEDIA_QUERY.xs} {
    place-items: center;
  }
`;

const BasicInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MEDIA_QUERY.lg} {
    padding: 0.5rem;
  }
`;

const SelectDateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MEDIA_QUERY.lg} {
    padding: 0.5rem;
  }

  ${MEDIA_QUERY.sm} {
    position: relative;
    padding: 0.5rem;
  }
`;

const SelectInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MEDIA_QUERY.lg} {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const StrongInfoLabel = styled.label`
  color: #474747;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;

  &::after {
    content: '*';
    color: #ff508b;
  }

  ${MEDIA_QUERY.lg} {
    margin-bottom: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-family: 'MapoFlowerIsland';
    font-size: 1.2rem;
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  width: 15rem;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0.8rem;
  border: 1px solid #c1c1c180;
  background-color: #fcfcfc;
  background: #fcfcfc url('images/calendar_icon.svg') no-repeat right 1rem center;

  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;

  /* IOS 기본 스타일 제거 */
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &::placeholder {
    color: #1e1f18;
    font-size: 1rem;
  }

  &:focus {
    outline: none;
  }

  ${MEDIA_QUERY.sm} {
    width: 15rem;
    height: 3.5rem;
    font-size: 1.2rem;

    &::placeholder {
      font-size: 1.2rem;
    }
  }

  ${MEDIA_QUERY.xs} {
    width: 12rem;
    font-size: 1rem;

    &::placeholder {
      font-size: 1rem;
    }
  }
`;

const BasicInfoInput = styled.input`
  color: #1e1f18;
  height: 3rem;
  border: 1px solid #c1c1c180;
  background-color: #fcfcfc;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;

  /* IOS 기본 스타일 제거 */
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  ${MEDIA_QUERY.lg} {
    width: 15rem;
    padding: 5px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #c1c1c1;
      font-size: 1rem;
    }
  }

  /* For WebKit browsers (Chrome, Safari) */
  &::-webkit-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Firefox */
  &::-moz-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  /* For Internet Explorer */
  &:-ms-input-placeholder {
    color: #c1c1c1;
    font-family: 'NanumSquareNeoRegular';
    font-size: 1rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 15rem;
    height: 3.5rem;
    font-size: 1.2rem;

    &::placeholder {
      color: #c1c1c1;
      font-size: 1.2rem;
    }
  }

  ${MEDIA_QUERY.xs} {
    width: 12rem;
    font-size: 1rem;

    &::placeholder {
      color: #c1c1c1;
      font-size: 1rem;
    }
  }
`;

const MobileSelectBox = styled.select`
  position: relative;
  border: 1px solid #c1c1c180;
  color: #1e1f18;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  background: #fcfcfc url('images/select_arrow.svg') no-repeat right 1rem center;

  appearance: none;

  padding: 1rem;
  background-color: #fcfcfc;
  border: 1px solid #c1c1c180;
  cursor: pointer;

  // select 화살표 변경
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/
  appearance: none;

  select::-ms-expand {
    display: none; /*for IE10,11*/
  }

  &:focus {
    outline: none;
  }

  ${MEDIA_QUERY.lg} {
    width: 15rem;
    height: 3rem;
    padding: 0.8rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 12rem;
    font-size: 1rem;
  }
`;

const SelectBoxWrapper = styled.div`
  position: relative;
  border: 1px solid #c1c1c180;
  color: #1e1f18;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  background: #fcfcfc url('images/select_arrow.svg') no-repeat right 1rem center;

  padding: 1rem;
  background-color: #fcfcfc;
  border: 1px solid #c1c1c180;

  list-style: none;
  z-index: 10;

  /* body의 가상 요소에 이미지를 불러와서 숨김 (이미지 캐시를 위해) */
  body::after {
    content: url('images/select_arrow.svg');
    visibility: hidden;
    height: 0;
  }

  &:focus {
    outline: none;
  }

  ${MEDIA_QUERY.lg} {
    width: 15rem;
    height: 3rem;
    padding: 0.8rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 12rem;
    font-size: 1rem;
  }
`;

const SelectValue = styled.label`
  position: relative;
  top: 0;
  left: 0;
  color: #1e1f18;
`;

interface SelectBoxProps {
  show: boolean;
}

const SelectBox = styled.ul<SelectBoxProps>`
  position: absolute;
  list-style: none;
  top: 1.8rem;
  left: 0;
  width: 100%;
  padding: 0;
  overflow: scroll;
  height: 10.2rem;
  max-height: ${(props) => (props.show ? 'none' : '0')};
  border: ${(props) => (props.show ? '1px solid #c1c1c180' : 'none')};
  background-color: #fcfcfc;
  cursor: pointer;
`;

const SelectOption = styled.li`
  width: 100%;
  height: 2.5rem;
  list-style: none;
  font-size: 1rem;
  color: #1e1f18;
  padding: 0.6rem 0.8rem;
  transition: background-color 0.2s ease-in;

  &:first-child::before {
    content: '';
    position: relative;
    font-size: 1rem;
    background: #fcfcfc url('images/select_arrow.svg') no-repeat right 1rem center;
  }

  &:hover {
    background-color: #c1c1c1;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
  }
`;

const MobileSelectOption = styled.option`
  font-size: 1rem;
  color: #1e1f18;

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
  }
`;

const InqueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;

  ${MEDIA_QUERY.sm} {
    margin-bottom: 1rem;
  }
`;

const InqueryLabel = styled.label`
  color: #474747;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  align-self: flex-start;

  ${MEDIA_QUERY.lg} {
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    font-family: 'MapoFlowerIsland';
  }
`;

const InqueryTextarea = styled.textarea`
  border: 1px solid #c1c1c180;
  background-color: #fcfcfc;
  resize: none;
  font-family: 'NanumSquareNeoRegular';
  padding: 0.5rem;

  /* IOS 기본 스타일 제거 */
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &:focus {
    outline: none;
  }

  ${MEDIA_QUERY.lg} {
    width: 31rem;
    height: 8rem;
    margin-bottom: 1rem;

    font-size: 1rem;
  }

  ${MEDIA_QUERY.sm} {
    padding: 1rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
  }
`;

const PersonalLabel = styled.label`
  color: #474747;
  font-family: 'NanumSquareNeoRegular';
  font-size: 1rem;
  align-self: flex-start;

  &::after {
    content: '*';
    color: #ff508b;
  }

  ${MEDIA_QUERY.lg} {
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    font-family: 'MapoFlowerIsland';
  }
`;

const PersonalWrapper = styled.div`
  display: flex;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    margin-left: 0.5rem;
  }
`;

const PersonalCheckbox = styled.input`
  display: none;

  &:checked + label::after {
    visibility: visible;
    background-color: #000;
  }
`;

const PersonalCheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 1.2rem;
  cursor: pointer;

  font-family: 'NanumSquareNeoRegular';

  ${MEDIA_QUERY.lg} {
    font-size: 1rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      text-align: center;
      background: url('/images/form_check.svg') no-repeat;
      border: 1px solid #c1c1c180;
      border-radius: 2px;
      background-color: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: url('/images/form_check.svg') no-repeat;
      background-position: center center;
      background-size: 0.6rem 0.6rem;
      border-radius: 1px;
      margin: 0.1rem;
      background-color: #fff;
      visibility: hidden;
    }
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    padding-left: 1.5rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      text-align: center;
      background: url('/images/form_check.svg') no-repeat;
      border: 1px solid #c1c1c180;
      border-radius: 2px;
      background-color: #fff;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.1rem;
      background: url('/images/form_check.svg') no-repeat;
      background-position: center center;
      background-size: 1rem 1rem;
      border-radius: 1px;
      background-color: #fff;
      visibility: hidden;
    }
  }
`;

const PersonalCheckboxText = styled.span`
  display: inline-block;

  ${MEDIA_QUERY.lg} {
    margin: 0.1rem 0 0 0.5rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1.2rem;
    margin-left: 0.7rem;
  }
`;

const FormButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222222;
  border: 0;
  cursor: pointer;

  color: #fff;

  ${MEDIA_QUERY.lg} {
    width: 7.2rem;
    height: 3rem;

    font-size: 1.2rem;
    margin-top: 2.2rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 10rem;
    height: 4rem;

    font-size: 1.5rem;
    margin-top: 2rem;
  }
`;

// form 에러 메시지
const InputErrorMsg = styled.span`
  color: #ce2c30;
  font-size: 0.9rem;

  ${MEDIA_QUERY.lg} {
    height: 1rem;
    margin-top: 0.3rem;
  }

  ${MEDIA_QUERY.sm} {
    font-size: 1rem;
  }

  ${MEDIA_QUERY.xs} {
    font-size: 0.8rem;
  }
`;
